<!--
File: Report_8_ConditionIndex.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <YearsDropdown class='region-select-dropdown' :label="$t('budget.select_year')" v-model="selectedYear"
            :initial_value="selectedYear" />
        </div>

        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <div class='md-layout-item md-size-50 md-small-size-100 md-medium-size-50'>
      <canvas v-if="!emptyData" ref="myChart" id="conditionIndexChart" width="200" height="100"></canvas>
    </div>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 250px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_km')" md-sort-by="total_km" md-numeric>
            {{ item.total_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_surveyed_km')" md-sort-by="total_surveyed_km" md-numeric>
            {{ item.total_surveyed_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_good_km')" md-sort-by="very_good_km" md-numeric>
            {{ item.very_good_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.good_km')" md-sort-by="good_km" md-numeric>
            {{ item.good_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.fair_km')" md-sort-by="fair_km" md-numeric>
            {{ item.fair_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.poor_km')" md-sort-by="poor_km" md-numeric>
            {{ item.poor_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_poor_km')" md-sort-by="very_poor_km" md-numeric>
            {{ item.very_poor_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--<md-table id="printFooter">
          <md-table-row>
            <md-table-head colspan="6">{{ $t('road_network.total_length') }}</md-table-head>
            <md-table-head md-numeric>{{ total_length }}</md-table-head>
          </md-table-row>
        </md-table>-->
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import YearsDropdown from '../Dropdowns/YearsDropdown.vue'
  import Chart from 'chart.js';
  import html2canvas from 'html2canvas';
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/mixins/customSortMixin'
  import printReport from '@/mixins/printMixin'

  export default {
   mixins: [customSortMixin, printReport],
    name: 'report-condition-index',
    data() {
      return {
        selectedRegion: null,
        selectedYear: null,       // 2024,
        btnDisabled: true,
        regionDescr: null,
        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        chart: null,
      }
    },
    components: {
      RegionsDropdown,
      YearsDropdown,
    },

    mounted() {
      this.showSpinner = true
      this.$store.commit('SET_REPORT_8', [])
      this.selectedYear = new Date().getFullYear()
      //this.reloadChart();
    },

    beforeDestroy() {
      if (this.chart) this.chart.destroy();
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const values = {
          region_id: this.selectedRegion,
          survey_year: this.selectedYear
        }

        this.$store.dispatch('REPORT_8_ROAD_CONDITION_INDEX', values).then(() => {
          this.btnDisabled = false
          this.reloadChart()
          this.showSpinner = false
        })
      },
      async reloadChart() {
        if (this.emptyData) return

        var labels = []
        var very_good = []
        var good = []
        var fair = []
        var poor = []
        var very_poor = []

        this.report.forEach((item) => {
          labels.push(item.region_description)
          very_good.push(item.very_good_km)
          good.push(item.good_km)
          fair.push(item.fair_km)
          poor.push(item.poor_km)
          very_poor.push(item.very_poor_km)
        })

        if (this.chart) this.chart.destroy(); // Уничтожаем предыдущий график перед созданием нового
        const ctx = document.getElementById('conditionIndexChart').getContext('2d');
        this.chart = new Chart(ctx, {
          type: 'horizontalBar',
          data: {
            labels: labels,
            datasets: [{
              label: this.$t('label.very_good_km'),
              data: very_good,
              backgroundColor: 'rgba(0, 255, 0, 0.2)',
              borderColor: 'rgba(0, 255, 0, 1)',
              borderWidth: 1
            }, {
              label: this.$t('label.good_km'),
              data: good,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1
            }, {
              label: this.$t('label.fair_km'),
              data: fair,
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1
            }, {
              label: this.$t('label.poor_km'),
              data: poor,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            }, {
              label: this.$t('label.very_poor_km'),
              data: very_poor,
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
              borderColor: 'rgba(255, 0, 0, 1)',
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              xAxes: [{
                stacked: true, // Включаем накопление для горизонтальной оси
                ticks: { beginAtZero: true }
              }],
              yAxes: [{
                stacked: true, // Включаем накопление для горизонтальной оси
                ticks: { beginAtZero: true }
              }]
            }
          }
        });
      },

      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('condition.condition_index')
        prtHtml += '&nbsp;' + this.$t('label.as_on') + this.selectedYear + '<br>'

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        const chartElement = this.$refs.myChart
        html2canvas(chartElement).then((canvas) => {
          const chartImage = canvas.toDataURL('image/png');
          prtHtml += `<img src="${chartImage}" style="width:100%;max-width:600px;">`;

          tableHeaders += `<th>${this.$t('road_network.total_km')}</th>
            <th>${this.$t('road_network.total_surveyed_km')}</th>
            <th>${this.$t('label.very_good_km')}</th>
            <th>${this.$t('label.good_km')}</th>
            <th>${this.$t('label.fair_km')}</th>
            <th>${this.$t('label.poor_km')}</th>
            <th>${this.$t('label.very_poor_km')}</th>
        </tr>`

          let tableRows = ''
          this.report.forEach(item => {
            tableRows += '<tr>'
            if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
            tableRows += `
            <td class='numCell'>${numFormat(item.total_km, 3)}</td>
              <td class='numCell'>${numFormat(item.total_surveyed_km, 3)}</td>
              <td class='numCell'>${numFormat(item.very_good_km, 3)}</td>
              <td class='numCell'>${numFormat(item.good_km, 3)}</td>
              <td class='numCell'>${numFormat(item.fair_km, 3)}</td>
              <td class='numCell'>${numFormat(item.poor_km, 3)}</td>
              <td class='numCell'>${numFormat(item.very_poor_km, 3)}</td>
          </tr>`
          })

          this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
        })
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
    },

    computed: {
      report() {
        //return this.$store.state.Reports.report_8_data;
        return this.customSort(this.$store.state.Reports.report_8_data, 'deu_description')
      },
      total() {
        return this.report.length
      },
      total_length() {
        return this.report.reduce((acc, currVal) => acc + currVal.length_km, 0,)
      },
      emptyData() {
        return this.report.length == 0
      }
    },

    watch: {
      selectedYear(newValue) {
        if (!newValue) this.selectedYear = new Date().getFullYear()
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>